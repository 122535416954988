// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_vertical-align-top__3NZQn {\n  vertical-align: top;\n}\n", "",{"version":3,"sources":["webpack://src/Components/Table/table.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":[".vertical-align-top {\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical-align-top": "table_vertical-align-top__3NZQn"
};
export default ___CSS_LOADER_EXPORT___;
