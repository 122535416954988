import React, { useEffect, useState } from 'react'
import MuiTable from '../Components/Table/Table'
import { Button } from '@material-ui/core'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Box
} from "@material-ui/core";
import useToggle from '../Hooks/useToggle'
import '../App.css'
import axiosInstance from "../axiosInstance";
import axiosMultiFormInstance from '../axiosMultiFormInstance';

const baseURL = "https://attacks.sbclabs.assertion.cloud";

const BrandManagement = () => {
    const [open, onClose] = useToggle(false);
    const [brandList, setBrandList] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)

    const getBrandNames = async () => {
        try {
            const response = await axiosInstance({
                method: "post",
                url: `/agentflow/getbrands`,
                baseURL,
            });
            setBrandList(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBrand = async (name) => {
        try {
            const email = localStorage.getItem("email");
            await axiosInstance({
                method: "post",
                url: `/agentflow/brands/delete/${name}`,
                baseURL,
                data: {
                    user_email: email
                }
            });
            getBrandNames()
        } catch (error) {
            console.log(error)
        }
    }

    const addBrand = async (formData) => {
        try {
            await axiosMultiFormInstance({
                method: "post",
                url: `/agentflow/brands`,
                baseURL,
                data: formData
            });
            getBrandNames()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBrandNames()
    }, [])

    return (
        <div>
            <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
                <DialogTitle style={{ paddingBottom: 0 }}>Add Brand</DialogTitle>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    let formEntries = Object.fromEntries(new FormData(e.target))
                    if (formEntries.brandname && formEntries.displayname && selectedFile) {
                        const formData = new FormData();
                        formData.append("brandname", formEntries.brandname);
                        formData.append("displayname", formEntries.displayname);
                        formData.append("image", selectedFile);
                        addBrand(formData)
                        onClose();
                        setSelectedFile(null)
                    } else {
                        alert("All fields are required")
                        return; 
                    }
                }}>
                    <DialogContent>
                        <Box
                            gap="1rem"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                "& > *": {
                                    display: "grid",
                                    gridTemplateColumns: "10rem 1fr",
                                    alignItems: "center",
                                },
                            }}
                        >
                            <Box style={{ marginBottom: '1rem' }}>
                                <Typography>Brand name</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="brandname"
                                />
                            </Box>
                            <Box style={{ marginBottom: '1rem' }}>
                                <Typography>Display name</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="displayname"
                                />
                            </Box>
                            <Box>
                                <Typography >Select image</Typography>
                                <input style={{ width: '15rem' }} type="file" onChange={(e) => setSelectedFile(e.target.files[0])} id="imageFile" accept="image/*" />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ padding: '1rem 1rem 1rem 0' }}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Add brand
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <div className="mb-8 text-2xl text-center">Brand management</div>
            <Button
                style={{ width: "200px", marginBottom: '1rem' }}
                type="submit"
                color="primary"
                variant="contained"
                onClick={onClose}
            >
                Add brand
            </Button>
            <MuiTable
                columns={[
                    {
                        title: "Brand name",
                        field: ["brandname"],
                    },
                    {
                        title: "Display name",
                        field: ["displayname"],
                    },
                    {
                        title: "Brand image",
                        modifier: (row) => (
                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                <img height="50" width="50" src={row.brandurl} alt="img" />
                            </div>
                        )
                    },
                    {
                        title: "Actions",
                        modifier: (row) => (
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: "center" }}>
                                <img
                                    onClick={() => deleteBrand(row?.brandname)}
                                    alt="delete"
                                    src="/images/deleteRed.svg"
                                    height="16"
                                    width="16"
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        ),
                    },
                ]}
                rows={brandList}
                pagination
                paginationProps={{
                    rowsPerPage: 5,
                }}
                emptyTableMessage="No brands found"
            />
        </div>
    )
}

export default BrandManagement