import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { Timeline, TimelineEvent } from "react-event-timeline";

const baseURL = "https://attacks.sbclabs.assertion.cloud";

const IdenityAssurance = () => {
  const [isLoadingAssignCustomer, setIsLoadingAssignCustomer] = useState(false);
  const [isLoadingTriggerConsent, setIsLoadingTriggerConsent] = useState(false);
  const [isLoadingPredictive, setIsLoadingPredictive] = useState(false);
  const [telcoBranding, setTelcoBranding] = useState(false);
  const [consentCallReason, setConsentCallReason] = useState("Your RM, Doe, will be calling you to discuss an onboarding offer specially curated for you as you are a premium customer of our bank. Kindly let us know when can we call...");
  const [customer_number, setCustomerNumber] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [history, setHistory] = useState([]);

  // const tempArr = [{
  //   message_time: "2024-10-10 10:15:02.403687",
  //   message: 'I received the payment for $543. Should be shi I received the payment for $543. Should be shipping the item within a couple of hours'
  // },
  // {
  //   message_time: "2024-10-10 10:30:02.403687",
  //   message: 'I received the payment for $543. Should be shi I received the payment for $543. Should be shipping the item within a couple of hours'
  // },
  // {
  //   message_time: "2024-10-10 10:30:59.403687",
  //   message: 'I received the payment for $543. Should be shi I received the payment for $543. Should be shipping the item within a couple of hours'
  // }]

  const formatDate = (dateString) => {
    const isoString = String(dateString).replace(' ', 'T ') + 'Z';

    const utcDate = new Date(isoString);

    const day = String(utcDate.getDate()).padStart(2, '0');
    const month = utcDate.toLocaleString('default', { month: 'short' });
    const year = String(utcDate.getFullYear()).slice(-2);
    const hours = String(utcDate.getHours() % 12 || 12).padStart(2, '0');
    const minutes = String(utcDate.getMinutes()).padStart(2, '0');
    const seconds = String(utcDate.getSeconds()).padStart(2, '0');
    const ampm = utcDate.getHours() >= 12 ? 'PM' : 'AM';
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // return `${day} ${month} ${year} ${hours}:${minutes}:${seconds} ${ampm} (${timeZone})`;

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const getBrandNames = async () => {
    try {
      const response = await axiosInstance({
        method: "post",
        url: `/agentflow/brandnames`,
        baseURL,
      });
      setBrandList(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getHistory = async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await axiosInstance({
        method: "post",
        url: `/agentflow/get-notification-user-progress`,
        baseURL,
        data: {
          user_email: email
        }
      });
      setHistory(response?.data?.user_progress_history)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setInterval(() => {
      getHistory()
    }, 2000);
  }, [])

  useEffect(() => {
    getBrandNames()
    getHistory()
  }, [])

  return (
    <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
      <div
        className="flex flex-col justify-start w-100 items-center "
        style={{ width: "80%" }}
      >
        <div className="w-full flex  flex-col items-center w-100 justify-center">
          <form
            style={{ position: 'relative' }}
            className="flex flex-col w-full items-center mb-12"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingTriggerConsent(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/trigger-consent-flow`,
                  baseURL,
                  data: {
                    user_email: email,
                    ...Object.fromEntries(new FormData(e.target)),
                  },
                });
              } catch (error) { }
              setIsLoadingTriggerConsent(false);
            }}
          >
            <div className="mb-4 text-2xl">Pre-call consent simulator</div>
            <div className="flex w-full justify-between w-full items-center">
              <TextField
                variant="outlined"
                // placeholder="Customer number"
                required
                style={{ width: "180px" }}
                type="number"
                name="customer_number"
                label="Customer number"
              />
              <TextField
                select
                variant="outlined"
                // placeholder="Brand name"
                style={{ width: "200px" }}
                required
                name="brand"
                label="Brand name"
              >
                {
                  brandList?.map((brand) => (
                    <MenuItem value={brand?.brandname}>{brand?.brandname}</MenuItem>
                  ))
                }
              </TextField>
              <div style={{ position: 'relative' }}>
                <TextField
                  required
                  label="Call Reason"
                  spellcheck="false"
                  name="call_reason"
                  style={{ width: "355px", padding: 0 }}
                  variant="outlined"
                  onChange={(e) => {
                    if (e.target.value.length <= 180) {
                      setConsentCallReason(e.target.value)
                    }
                  }}
                  value={consentCallReason}
                  multiline
                  maxRows={20}
                />
                <span style={{ position: 'absolute', bottom: -20, right: 0, color: 'grey', fontSize: '14px' }}>{consentCallReason.length} / 180</span>
              </div>
              <Button
                style={{ width: "150px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingTriggerConsent ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Trigger consent flow"
                )}
              </Button>
            </div>
          </form>
          <form
            className="flex flex-col w-full items-center mb-12"
            style={{ position: 'relative' }}
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingPredictive(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/trigger-predictive-flow`,
                  baseURL,
                  data: {
                    user: email,
                    ...Object.fromEntries(new FormData(e.target)),
                    telcobranding: telcoBranding
                  },
                });
              } catch (error) { }
              setIsLoadingPredictive(false);
            }}
          >
            <div className="mb-4 text-2xl">Call branding simulator</div>
            <div className="flex justify-between w-full items-center">
              <TextField
                variant="outlined"
                label="Customer number"
                required
                type="number"
                name="customer_number"
              />
              <TextField
                select
                variant="outlined"
                label="Brand name"
                style={{ width: "200px" }}
                required
                name="brand"
              >
                {
                  brandList?.map((brand) => (
                    <MenuItem value={brand?.brandname}>{brand?.brandname}</MenuItem>
                  ))
                }
              </TextField>
              <TextField
                variant="outlined"
                label="Call reason"
                required
                type="text"
                name="call_reason"
              />

              <Button
                style={{ width: "200px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingPredictive ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "make call"
                )}
              </Button>

            </div>
            <FormControlLabel style={{ position: 'absolute', right: -163, top: 58, display: "none" }}
              control={
                <Checkbox
                  name="telcobranding"
                  color="primary"
                  checked={telcoBranding}
                  onChange={(e) => setTelcoBranding(e.target.checked)}
                />}
              label="TelcoBranding" name="telcobranding" />
          </form>

          <div style={{ border: '1px solid grey', borderRadius: '6px', width: '100%', height: '14rem', maxHeight: "14rem", overflow: "auto" }}>
            {history.length ? <Timeline>
              {
                history.sort((a, b) => new Date(b.message_time) - new Date(a.message_time)).map((event) => (
                  <TimelineEvent
                    title={event.message}
                    createdAt={formatDate(new Date(event.message_time))}
                    style={{ marginBottom: "5px" }}
                    titleStyle={{ padding: 0, margin: 0 }}
                    bubbleStyle={{
                      maxHeight: "10px",
                      maxWidth: "10px",
                      marginLeft: "12px",
                      borderColor: "#3f51b5",
                    }}
                  />
                ))
              }
            </Timeline> :
              <p style={{ textAlign: "center", marginTop: "6rem" }}>No records found</p>}
          </div>

          {/* Hidden from UI - start */}
          <form
            style={{ display: "none" }}
            className="flex flex-col w-full items-center mb-12"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingAssignCustomer(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/add-customer-mapping`,
                  baseURL,
                  data: {
                    user_email: email,
                    mode: "preview",
                    ...Object.fromEntries(new FormData(e.target)),
                  },
                });
              } catch (error) { }
              setIsLoadingAssignCustomer(false);
            }}
          >
            <div className="mb-4 text-2xl">Preview mode section</div>
            <div className="flex justify-between w-full items-center">
              <TextField
                variant="outlined"
                placeholder="Customer number"
                required
                type="number"
                name="customer_number"
              />

              <Button
                style={{ marginLeft: "10px", width: "200px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingAssignCustomer ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Trigger preview flow"
                )}
              </Button>
            </div>
          </form>
          <div
            style={{
              width: "100%",
              height: "2px",
              background: "grey",
              marginBottom: "1rem",
              display: "none",
            }}
          />

          <div style={{ display: "none" }} className="w-full flex justify-between mb-4 dis-none">
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  try {
                    const email = localStorage.getItem("email");
                    const response = await axiosInstance({
                      method: "post",
                      url: `agentflow/fetch-user-assignment`,
                      baseURL,
                      data: {
                        user_email: email,
                      },
                    });
                    setCustomerNumber(response.data.customer_number);
                  } catch (error) { }
                  setIsLoadingAssignCustomer(false);
                }}
              >
                Get customer association
              </Button>
              <span className="ml-4">{customer_number}</span>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                try {
                  const email = localStorage.getItem("email");
                  await axiosInstance({
                    method: "post",
                    url: `agentflow/remove-customer-mapping`,
                    baseURL,
                    data: {
                      user_email: email,
                    },
                  });
                } catch (error) { }
                setIsLoadingAssignCustomer(false);
              }}
            >
              Remove customer association
            </Button>
          </div>
          {/* Hidden from UI - end */}

          {/* 
        <div className="mb-20" style={{ alignSelf: "flex-start" }}>
          {customer_number}
        </div> */}

          {/* <form
          className="flex  items-center"
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoadingCall(true);

            try {
              // const numbers = JSON.parse(
              //   localStorage.getItem("number") || null
              // );
              // const email = localStorage.getItem("email");

              await axiosInstance({
                method: "post",
                url: `/identityassuranceagentcalling`,
                data: {
                  from_user: "+14694936080",
                  to_user: Object.fromEntries(new FormData(e.target)).to_user,
                  call_reason: Object.fromEntries(new FormData(e.target))
                    .call_reason,
                },
              });
            } catch (error) {}
            setIsLoadingCall(false);
          }}
        >
          <TextField
            variant="outlined"
            placeholder="To user"
            required
            type="number"
            name="to_user"
          />
          <TextField
            variant="outlined"
            placeholder="Call reason"
            style={{ margin: "0 2rem" }}
            required
            name="call_reason"
          />
          <Button
            style={{ marginLeft: "10px" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {isLoadingCall ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Make outbound call"
            )}
          </Button>
        </form> */}
        </div>
      </div>
    </div>
  );
};

export default IdenityAssurance;
