import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Flex from "../Flex/Flex";
import cssClasses from "./table.module.css";
import { useMemo } from "react";

const MuiHeaderTableCell = withStyles({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    background: "#3f51b5",
    color: "#fff",
    borderRight: "0.25px",
    borderRightColor: "#dcdcdc",
    borderRightStyle: "solid",
  },
})(TableCell);
const MuiLastHeaderTableCell = withStyles({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    background: "#3f51b5",
    color: "#fff",
  },
})(TableCell);

const useStyles = makeStyles({
  table: {
    backgroundColor: "#ffffff",
    fontSize: "calc(2px + 1.2vw)",
  },

  container: {
    border: "0.5px solid #292c9d",
  },
});

const MuiTable = ({
  columns,
  rows = [],
  pagination = false,
  className = "",
  headerTextProps = {},
  cellTextProps = {},
  paginationProps = {
    rowsPerPage: 5,
  },
  loading = false,
  emptyTableMessage = "No data found",
  hideBorder = false,
  hideHeader = false,
  search = false,
  displayRow = () => true,
  sort = {
    field: "",
    order: "",
  },
}) => {
  const MuiTableCell = useMemo(
    () =>
      withStyles({
        root: {
          padding: "0.5rem 0.25rem",
          border: hideBorder && "none",
          borderRight: hideBorder ? "none" : "0.25px #dcdcdc solid",
        },
      })(TableCell),
    [hideBorder]
  );

  console.log("MuiTableCell", headerTextProps);

  const MuiLastTableCell = useMemo(
    () =>
      withStyles({
        root: {
          paddingLeft: "0.25rem",
          paddingRight: "0.25rem",
          border: hideBorder && "none",
        },
      })(TableCell),
    [hideBorder]
  );

  const classes = useStyles();

  const rowsPerPage = paginationProps.rowsPerPage ?? 5;

  const totalPages = paginationProps.total
    ? Math.ceil(paginationProps.total / rowsPerPage)
    : Math.ceil(rows.length / rowsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  let displayRows = pagination
    ? rows.filter(
        (_, index) =>
          index < currentPage * rowsPerPage &&
          index >= (currentPage - 1) * rowsPerPage
      )
    : rows;

  const [searchTerm, setSearchTerm] = useState("");

  displayRows = search?.field
    ? rows.filter((row) =>
        searchTerm
          ? Array.isArray(search.field)
            ? search.field.some((field) => row[field].includes(searchTerm))
            : row[search.field].includes(searchTerm)
          : true
      )
    : displayRows;

  displayRows = sort.field
    ? displayRows.sort((a, b) => {
        if (sort.order === "asc") {
          return -b[sort.field] + a[sort.field];
        } else return -a[sort.field] + b[sort.field];
      })
    : displayRows;

  return (
    <Flex
      alignItems="center"
      direction="col"
      style={{ position: "relative" }}
      className={className}
    >
      {search ? (
        <TextField
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          label="Search"
          variant="outlined"
          style={{ position: "absolute", top: -50, right: 0 }}
        />
      ) : null}
      <TableContainer className={!hideBorder && classes.container}>
        <Table aria-label="simple table" className={classes.table}>
          {!hideHeader && (
            <TableHead>
              <TableRow>
                {columns.map((column, index) =>
                  index === columns.length - 1 ? (
                    <MuiLastHeaderTableCell
                      colSpan={column.colSpan || 1}
                      key={`${index}-${column.title}`}
                      align="center"
                    >
                      {typeof column.title === "string" ? (
                        <Typography {...headerTextProps}>
                          {column.title}
                        </Typography>
                      ) : typeof column.title === "function" ? (
                        column.title()
                      ) : (
                        column.title
                      )}
                    </MuiLastHeaderTableCell>
                  ) : (
                    <MuiHeaderTableCell
                      colSpan={column.colSpan || 1}
                      key={`${index}-${column.title}`}
                      align="center"
                    >
                      {typeof column.title === "string" ? (
                        <Typography {...headerTextProps}>
                          {column.title}
                        </Typography>
                      ) : typeof column.title === "function" ? (
                        column.title()
                      ) : (
                        column.title
                      )}
                    </MuiHeaderTableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {displayRows.filter(displayRow).map((row, index) => (
              <TableRow key={`${index}-${row.title}`}>
                {columns.map((column, colIndex) => {
                  let cellValue = Array.isArray(column.field)
                    ? column.field.reduce((acc, data) => acc[data], row)
                    : row[column.field] ?? (column.defaultValue || "");
                  cellValue = column.modifier
                    ? column.modifier(row, index, cellValue)
                    : cellValue;
                  const cell =
                    typeof row[column.title] === "object" ? (
                      cellValue
                    ) : (
                      <Typography size="1w" {...cellTextProps}>
                        {cellValue}
                      </Typography>
                    );

                  return colIndex === columns.length - 1 ? (
                    <MuiLastTableCell
                      colSpan={column.colSpan || 1}
                      className={
                        cssClasses[`vertical-align-${column.verticalAlign}`]
                      }
                      key={`${index}-${row.title}-${column.title}-${colIndex}`}
                      align={column.cellAlign || "center"}
                    >
                      {cell}
                    </MuiLastTableCell>
                  ) : (
                    <MuiTableCell
                      colSpan={column.colSpan || 1}
                      className={
                        cssClasses[`vertical-align-${column.verticalAlign}`]
                      }
                      style={{ wordBreak: "break-word" }}
                      key={`${index}-${row.title}-${column.title}-${colIndex}`}
                      align={column.cellAlign || "center"}
                    >
                      {cell}
                    </MuiTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Flex justify="center" className="p-30">
          <CircularProgress />
        </Flex>
      )}
      {!loading && displayRows.length === 0 && (
        <Flex justify="center" className="p-30">
          {emptyTableMessage}
        </Flex>
      )}
      {pagination && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: paginationProps.width ?? "20%",
            justifyContent: "space-between",
            color: "grey",
          }}
        >
          <IconButton
            disabled={paginationProps.disablePrevious || false}
            onClick={() =>
              paginationProps.handlePrevious
                ? paginationProps.handlePrevious()
                : setCurrentPage(
                    currentPage === 1 ? currentPage : currentPage - 1
                  )
            }
          >
            <ChevronLeftIcon />
          </IconButton>
          {paginationProps.centerText ??
            `Page ${paginationProps.page + 1 || currentPage} of ${
              totalPages || 1
            }`}
          <IconButton
            disabled={paginationProps.disableNext || false}
            onClick={() =>
              paginationProps.handleNext
                ? paginationProps.handleNext()
                : setCurrentPage(
                    currentPage === totalPages ? currentPage : currentPage + 1
                  )
            }
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      )}
    </Flex>
  );
};

export default MuiTable;
