import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Simulation from "./Layout/Simulation";
import ExtensionTakeOver from "./Pages/ExtensionTakeOver";
import TollFraudAttack from "./Pages/TollFraudAttack";
import PageNotFound from "./Pages/PageNotFound";
import Login from "./Pages/Login";
import RoboCall from "./Pages/RoboCall";
import SpamCall from "./Pages/SpamCall";
import OutboundCall from "./Pages/OutboundCall";
import IdenityAssurance from "./Pages/IdenityAssurance";
import BrandManagement from "./Pages/BrandManagement";

const LoginCheck = ({ children }) => {
  if (localStorage.getItem("email")) {
    return children;
  }

  window.location.href = "/login";
};

const ExtensionTakeOverPage = () => (
  <LoginCheck>
    <Simulation>
      <ExtensionTakeOver />
    </Simulation>
  </LoginCheck>
);
const TollFraudAttackPage = () => (
  <LoginCheck>
    <Simulation>
      <TollFraudAttack />
    </Simulation>
  </LoginCheck>
);

const RoboCallPage = () => (
  <LoginCheck>
    <Simulation>
      <RoboCall />
    </Simulation>
  </LoginCheck>
);
const SpamCallPage = () => (
  <LoginCheck>
    <Simulation>
      <SpamCall />
    </Simulation>
  </LoginCheck>
);

const OutBoundCallPage = () => (
  <LoginCheck>
    <Simulation>
      <OutboundCall />
    </Simulation>
  </LoginCheck>
);

const IdenityAssurancePage = () => (
  <LoginCheck>
    <Simulation>
      <IdenityAssurance />
    </Simulation>
  </LoginCheck>
);

const BrandManagementPage = () => (
  <LoginCheck>
    <Simulation>
      <BrandManagement />
    </Simulation>
  </LoginCheck>
);

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/remote-worker-breach"
          exact
          component={ExtensionTakeOverPage}
        />
        <Route path="/robocall" exact component={RoboCallPage} />
        <Route path="/spam-call" exact component={SpamCallPage} />
        <Route
          path="/toll-fraud-attack"
          exact
          component={TollFraudAttackPage}
        />
        <Route path="/outbound-call" exact component={OutBoundCallPage} />
        <Route path="/login" exact component={Login} />
        <Route path="/identity-assurance" exact component={IdenityAssurancePage} />
        <Route path="/identity-assurance/multitenant" exact component={BrandManagementPage} />
        <Route path="/" component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
