import axios from "axios";

const axiosMultiFormInstance = axios.create({
  baseURL: "https://attacks.sbclabs.assertion.cloud/attack",
  // baseURL: "https://jsonplaceholder.typicode.com/todos",
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default axiosMultiFormInstance;
